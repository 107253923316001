<template>
  <header class="header" role="banner">
    <div class="logo">
      <router-link to="/">
        <div style="display: flex; align-items: center">
          <img src="@/assets/logo.png" alt="论文说 Logo" class="logo-img mr-3" />
          <h1 style="font-size: 1.3rem">论文说</h1>
        </div>
      </router-link>
    </div>
    <nav class="nav" role="navigation" aria-label="主导航">
      <router-link to="/square" class="nav-link">广场</router-link>
      <template v-if="!isLoggedIn">
        <router-link to="/login" class="login-btn">登录</router-link>
      </template>
      <template v-else>
        <router-link to="/studio" class="nav-link">工作室</router-link>
        <!-- <span class="username">{{ userEmail }}</span> -->
        <button @click="handleLogout" class="logout-btn" aria-label="退出登录">
          退出
        </button>
      </template>
    </nav>
  </header>
</template>

<script>
import { logout } from "@/api/auth";

export default {
  name: "AppHeader",
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("access_token");
    },
    userEmail() {
      return localStorage.getItem("user_email");
    },
  },
  methods: {
    async handleLogout() {
      try {
        await logout();
        localStorage.clear();
        window.location.reload();
      } catch (error) {
        console.error("退出失败:", error);
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo-img {
  height: 40px;
}

.nav {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav a {
  color: #64748b;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #3b82f6;
}

.login-btn {
  color: #64748b;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-btn:hover {
  color: #3b82f6;
}

.username {
  color: #64748b;
}

.logout-btn {
  background-color: transparent;
  color: #64748b;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.logout-btn:hover {
  color: #3b82f6;
}

@media (max-width: 768px) {
  .nav {
    gap: 1rem;
  }

  .header {
    padding: 0.75rem 1rem;
  }

  .logo-img {
    height: 32px;
  }
}

@media (max-width: 480px) {
  .nav {
    gap: 0.5rem;
  }
}
</style>
