import Vue from 'vue'
import VueRouter from 'vue-router'
import ResultsPage from '../views/Results.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/studio',
    name: 'TaskList',
    component: () => import('../views/TaskList.vue')
  },  
  {
    path: '/create/:file_uuid',
    name: 'CreateTask',
    component: () => import('../views/SubmitTask.vue')
  },
  {
    path: '/result/:file_uuid',
    name: 'Results',
    component: ResultsPage
  },
  {
    path: '/playlist/:playlist_uuid',
    name: 'Playlist',
    component: () => import('../views/Playlist.vue')
  },
  {
    path: '/square',
    name: 'Square',
    component: () => import('../views/Square.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue')
  }
  // 其他路由...
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
